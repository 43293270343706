import Datatable from './Components/Datatable';
import { useState, useEffect } from 'react';
import GasolinerasManager from './utils/GasolinerasManager';
import axios from 'axios';
import Loader from './Components/Loader';

function App(){
  const [ updateAt, setUpdateAt ] = useState('');
  const [ gasolineras, setGasolineras ] = useState([]);
  const [ gasolinerasList, setGasolinerasList ] = useState([]);
  const [ cities, setCities ] = useState([]);
  const [ provincias, setProvincias ] = useState([]);
  const [ selectedState, setSelectedState ] = useState('0');
  const [ isLoading, setIsLoading ] = useState(true);
  const [ refreshData, setRefreshData ] = useState(true);
  let GasolinerasData = new GasolinerasManager();

  function handleFilterStateChange(e){
    setIsLoading(true);
    setSelectedState(e.target.value);
    setGasolinerasList(GasolinerasData.getByState(e.target.value, gasolineras));
    setIsLoading(false);
  }

  function handleFilterCityChange(e){
    setIsLoading(true);
    if(e.target.value === '0'){
      setGasolinerasList(GasolinerasData.getByState(selectedState, gasolineras));
    }else{
      setGasolinerasList(GasolinerasData.getByCity(e.target.value, gasolineras));
    }
    
    setIsLoading(false);
  }
  
  function citiesList(data){
    let list = [];
    data.forEach((es,ind) =>{
      if(list[es.IDProvincia]){
        if(!list[es.IDProvincia].find(item => { return item.IDMunicipio == es.IDMunicipio })  && es['Tipo Venta'] === 'P'){
          list[es.IDProvincia].push({'IDMunicipio':es.IDMunicipio, 'name':es.Municipio});
        }
      }else{
        list[es.IDProvincia] = [];
        list[es.IDProvincia].push({'IDMunicipio':es.IDMunicipio, 'name':es.Municipio});
      }
      
    });
    return list;
  }
  const getData=()=>{
    fetch('precios.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(data) {
        setGasolineras(data.ListaEESSPrecio);
        setGasolinerasList(data.ListaEESSPrecio);
        setUpdateAt(data.Fecha);
        setCities(citiesList(data.ListaEESSPrecio));
        setIsLoading(false);
      });
  }
  const getProvinciasOption = ()=> {
    fetch('provincias.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(data) {
        const sorted = data.sort((a,b)=>{
          return parseInt(a.IDProvincia) - parseInt(b.IDProvincia);
        });
        setProvincias(sorted);
      });
  }

  useEffect((e) => {
    if(refreshData === true){
      setIsLoading(true);
      setRefreshData(false);
      // axios.get('https://sedeaplicaciones.minetur.gob.es/ServiciosRESTCarburantes/PreciosCarburantes/EstacionesTerrestres/')
      // .then((response) => {
      //   const data = response.data;
      //   setGasolineras(data.ListaEESSPrecio);
      //   setGasolinerasList(data.ListaEESSPrecio);
      //   setRefreshData(false);
      //   setIsLoading(false);
      //   setUpdateAt(data.Fecha);
      //   setCities(citiesList(data.ListaEESSPrecio));
      // });
      getData();
      getProvinciasOption();
    }
  });

  if(isLoading){
    return (<div className="container"><Loader/></div>);
  }

  return (
    <div className="app">
      <header className="container app_header">
        <div className="row">Precios por gasolinera {updateAt}</div>
      </header>
      <div className="container filters">
        <div className="row table_filter">
          <select value={selectedState} id="tableFilterState" onChange={(e) => handleFilterStateChange(e)}>
            <option value="0">Filtrar por provincia</option>
            {provincias.map((state => {
              return (<option key={state.IDProvincia} value={state.IDProvincia}>{state.name}</option>);
            }))}
          </select>
        </div>
        {selectedState !== '0' && <div className="row table_filter">
          <select id="tableFilterCity" onChange={(e) => handleFilterCityChange(e)}>
            <option value="0">Filtrar por ciudad</option>
            {cities[selectedState].map((city => {
              return (<option key={city.IDMunicipio} value={city.IDMunicipio}>{city.name}</option>);
            }))}
          </select>
        </div>}
      </div>
      <div className="container data_table">
        <Datatable gasolineras={gasolinerasList}/>
      </div>
    </div>
  );
}

export default App;
