'use strict';

class GasolinerasManager {
  minPrice = {
    'gA':0,
    'g95':0,
    'g98':0
  };
  maxPrice = {
    'gA':0,
    'g95':0,
    'g98':0
  };
  all = [];
  updateAt = '';

  calcMinMax(data){
    let minPrice = {
      'gA':99,
      'g95':99,
      'g98':99
    };
    let maxPrice = {
      'gA':0,
      'g95':0,
      'g98':0
    };
    data.forEach((gasolinera, ei) => {
      const gasA = parseFloat(gasolinera['Precio Gasoleo A'].replace(',', '.'));
      const gas98 = parseFloat(gasolinera['Precio Gasolina 98 E5'].replace(',', '.'));
      const gas95 = parseFloat(gasolinera['Precio Gasolina 95 E5'].replace(',', '.'));
      if(gas98 > maxPrice.g98){
        maxPrice.g98 = gas98;
      }
      if(gas95 > maxPrice.g95){
        maxPrice.g95 = gas95;
      }
      if(gasA > maxPrice.gA){
        maxPrice.gA = gasA;
      }
      if(gas98 < minPrice.g98 && gas98 > 0){
        minPrice.g98 = gas98;
      }
      if(gas95 < minPrice.g95 && gas95 > 0){
        minPrice.g95 = gas95;
      }
      if(gasA < minPrice.gA && gasA > 0){
        minPrice.gA = gasA;
      }
    });
    this.maxPrice = maxPrice;
    this.minPrice = minPrice;
  }

  getByState(state, data){
    if(state === '0'){
      return data;
    }
    const gasolineras = data;
    return gasolineras.filter((gasolinera) => {
      return gasolinera.IDProvincia === state && gasolinera['Tipo Venta'] === 'P';
    });
  }

  getByCity(city, data){
    if(city === '0'){
      return data;
    }
    const gasolineras = data;
    return gasolineras.filter((gasolinera) => {
      return gasolinera.IDMunicipio === city && gasolinera['Tipo Venta'] === 'P';
    });
  }

  getPriceColor(gasolinaType, price){
    price = parseFloat(price.replace(',', '.'));
    const gmedia = (this.maxPrice[gasolinaType] + this.minPrice[gasolinaType]) / 2;
    const gmin = (gmedia - (gmedia / 10));
    const gmax = (gmedia + (gmedia / 10));
    if(price == this.minPrice[gasolinaType]){
      return 'green';
    }
    if(price <= gmedia){
      return 'orange';
    }
    if(price >= this.maxPrice[gasolinaType]){
      return 'red';
    }
    return 'black';
  }
}

export default GasolinerasManager;