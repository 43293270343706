import React from 'react';
import './datatable.scss';
import GasolinerasManager from '../../utils/GasolinerasManager';

function Datatable({gasolineras}){
  let gManager = new GasolinerasManager();
  gManager.all = gasolineras;
  gManager.calcMinMax(gasolineras);

  // console.log('datatable gasolineras',gasolineras);
  function getGMapsLink(item){
    return 'https://www.google.com/maps/search/?api=1&query=' + item['Latitud'].replace(',', '.') + '%2C' + item['Longitud (WGS84)'].replace(',', '.');
  }

  return (
    <div className="row table_data">
      <p>Puedes hacer click en cada una de las estaciones para abrir en el mapa.</p>
      <table>
        <thead>
          <tr>
            <td>Gasolinera</td>
            <td>Gasoleo A</td>
            <td>Gasolina 95</td>
            <td>Gasolina 98</td>
          </tr>
          <tr className="minmax">
            <td>&nbsp;</td>
            <td><span className="green">{gManager.minPrice.gA.toString().replace('.',',')}</span>-<span className="red">{gManager.maxPrice.gA.toString().replace('.',',')}</span></td>
            <td><span className="green">{gManager.minPrice.g95.toString().replace('.',',')}</span>-<span className="red">{gManager.maxPrice.g95.toString().replace('.',',')}</span></td>
            <td><span className="green">{gManager.minPrice.g98.toString().replace('.',',')}</span>-<span className="red">{gManager.maxPrice.g98.toString().replace('.',',')}</span></td>
          </tr>
        </thead>
        <tbody>
          {gasolineras.map((item => {
            return (<tr key={item.IDEESS}>
              <td><span>
                <a target="_blank" href={getGMapsLink(item)}>{item['Rótulo']} - ({item['Dirección']}) - {item['Municipio']}</a>
              </span></td>
              <td><span className="typeLabel">Gasoleo A</span><span className={gManager.getPriceColor('gA', item['Precio Gasoleo A'])}>{item['Precio Gasoleo A']}</span></td>
              <td><span className="typeLabel">Gasolina 95</span><span className={gManager.getPriceColor('g95', item['Precio Gasolina 95 E5'])}>{item['Precio Gasolina 95 E5']}</span></td>
              <td><span className="typeLabel">Gasolina 98</span><span className={gManager.getPriceColor('g98', item['Precio Gasolina 98 E5'])}>{item['Precio Gasolina 98 E5']}</span></td>
            </tr>);
          }))}
        </tbody>
      </table>
    </div>
  );
}

export default Datatable;